import React from 'react';
import { navigate } from 'gatsby-link';


const API_KEY = process.env.REACT_APP_MG_API_KEY
const DOMAIN = process.env.REACT_APP_MG_DOMAIN

const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY});


function encode(data) {
  const formData = new FormData()


  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData

}



class CoVid19 extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] })
  }

  handleSubmit = e => {


    let fileinput = document.getElementById('fileinput');
    let file = fileinput.files[0];
    //disableEmptyInputs();

    if (file !== undefined){
        if (file.size < 1048576){
        e.preventDefault()
        const form = e.target;

            let formdata
            formdata = encode({
             'form-name': form.getAttribute('name'),
             ...this.state,
           })


              for (let value of formdata.values()) {
                 console.log(value);
              }

        fetch('/', {
          method: 'POST',
          body: formdata,
        })
          .then(() => {

            const messageData = {
              from: 'Art Open <bok@artopen.pl>',
              to: document.getElementById('adres emailII').value,
              subject: 'Thank you for sending your inquiry!',
              html: `
    <div style="background-color: #23c495; text-align: center">
    <a target="_blank" rel="noopener noreferrer" href="https://artopen.co/">
      <img
        src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/logo-mail.png?v=1651011958996"
        width="300px"
    /></a>
    </div>

    <div
    style="
      background-color: #23c495;
      display: flex;
      align-items: center;
      justify-content: center;
    "
    >
    <div style="display: block; text-align: left; color: white; padding: 20px">
      <h1 style="font-size: 1.5em">Thank you!</h1>
      <h2
        style="
          border-radius: 5px;
          border-bottom: 15px solid #fcc515;
          font-size: 2em;
          max-width: 80%;
        "
      >
        For sending a message from the Art Open website
      </h2>
      <sub style="margin-bottom: 20px"
        >Our team <strong>will contact you soon.</strong><br />
        On working days, we <strong>usually complete quotations within<strong> 24h./strong>.</sub
      >
    </div>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://artopen.co/contact/"
        ><img src="https://artopen.co/img/newsletter.svg" width="100%"
      /></a>
    </div>
    </div>
    <br />
    <h3 style="font-size: 2em; text-align: center; margin-top: 30px">
    Get to know us better:
    <br />
    <sub style="font-size: 0.5em"
      >Find out
      <a
        target="_blank"
        style="color: #23c495"
        rel="noopener noreferrer"
        href="https://artopen.co/about-us/"
        >who are we</a
      >.</sub
    >
    </h3>

    <div style="display: flex; justify-content: center">
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/offer/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l4.png?v=1651011237576"
          width="100%"
        /><br />
        <p style="text-align: center">OFFER</p>
      </div></a
    >
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/blog/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l3.png?v=1651011237576"
          width="100%"
        /><br />
        <p style="text-align: center">BLOG</p>
      </div></a
    >
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/portfolio/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l2.png?v=1651011237791"
          width="100%"
        /><br />
        <p style="text-align: center">PORTFOLIO</p>
      </div></a
    >
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/catalogs/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l1.png?v=1651011237864"
          width="100%"
        /><br />
        <p style="text-align: center">CATALOGS</p>
      </div></a
    >
    </div>
    <br /><br />
    <h3 style="text-align: center; font-size: 2em">
    Join the action:<br />
    <sub style="font-size: 0.5em"
      >View
      <a
        target="_blank"
        style="color: #23c495"
        rel="noopener noreferrer"
        href="https://artopen.co/blog/cleaning-action-open-for-environment-v-2/"
        >action footage in 2021</a
      >.</sub
    >
    </h3>
    <br /><br />
    <div style="display: block; text-align: center">
    <div>
      <img
        src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/akcja-sprzatanie-3.png?v=1651239639520"
        width="100%"
        style="min-width: 200px; max-width: 500px"
      />
    </div>
    <div
      style="
        display: block;
        text-align: center;
        padding-left: 0px;
        color: #23c495;
      "
    >
      <h1 style="font-size: 1.5em">
      Be ECO with us and join the Cleanup Action vol.III
      </h1>
      <p style="max-width:90%;margin-left:5%;">
        Being aware of the seriousness of the problem of littering, while being an agency with a strong pro-ecological profile at the same time, we decided to take matters into our own hands and for the third time we are organizing a local cleaning action. 
        <br /><br /><br />
        <a
          style="
            color: #23c495;
            border-radius: 15px;
            padding: 10px 20px;
            border: 1px solid #23c495;
          "
          target="_blank"
          rel="noopener noreferrer"
          href="https://artopen.co/akcja-sprzatanie-vol-3/"
          >join us</a
        >
      </p>
    </div>
    </div>
    <br /><br />
    <h3 style="text-align: center; font-size: 2em">
    Check out the blog:<br />
    <sub style="font-size: 0.5em"
      >Read
      <a
        target="_blank"
        style="color: #23c495"
        rel="noopener noreferrer"
        href="https://artopen.co/blog/"
        >our articles</a
      >.</sub
    >
    </h3>
    <div
    style="
      background-color: #23c495;
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
    >
    <div style="display: block; text-align: left; padding: 20px; color: white">
      <h1 style="font-size: 2em">How to create a website?</h1>
      <p>
      What does web development look like and what does it consist of? Nowadays, a company website is a must have no matter the company’s profile. However, considering the competition online, simply having a website may not be enough.
        <br /><br /><br />
        <a
          style="
            color: white;
            border-radius: 15px;
            padding: 10px 20px;
            border: 1px solid white;
          "
          target="_blank"
          rel="noopener noreferrer"
          href="https://artopen.co/blog/jak-stworzyc-strone-internetowa/"
          >read more</a
        >
      </p>
    </div>
    <div>
      <img
        src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/jak.png?v=1651241484223"
        width="100%"
      />
    </div>
    </div>
    <br /><br /><br />
    <div style="display: flex; justify-content: center">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://facebook.com/artopenstudioreklamy/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/fb.png?v=1651013201802"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://twitter.com/AReklamy/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/t.png?v=1651013201802"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.youtube.com/channel/UCR45VIn9iEmnQo7JU_FL2uQ/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/yt.png?v=1651013201802"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.linkedin.com/company/art-open-studio-reklamy/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l.png?v=1651013202010"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.behance.net/ArtOpenStudioReklamy/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/b.png?v=1651013202080"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/artopen.pl/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/i.png?v=1651013202263"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    </div>

    <p style="text-align: center; font-size: 10px; margin-top: 10px">
    We hope you enjoy our content. If you don't
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/contact/"
      >here you can withdraw your subscriptions.</a
    >
    </p>
    <br /><br />

    `
            };
            console.log(client)

            client.messages.create(DOMAIN, messageData)
             .then((res) => {
               console.log(res);
             })
             .catch((err) => {
               console.error(err);
             });


            navigate('/contact/sukces/')
          }).catch(error => alert(error))
      } else {
        alert('The file is too large. The maximum size is 1MB, try again with a smaller file');
        }
    } else {
    e.preventDefault()
    const form = e.target;


        let formdata
        formdata = encode({
         'form-name': form.getAttribute('name'),
         ...this.state,
       })


          for (let value of formdata.values()) {
             console.log(value);
          }

    fetch('/', {
      method: 'POST',
      body: formdata,
    })
      .then(() => {

        const messageData = {
          from: 'Art Open <bok@artopen.pl>',
          to: document.getElementById('adres emailVII').value,
          subject: 'Thank you for sending your inquiry!',
          html: `
<div style="background-color: #23c495; text-align: center">
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/">
  <img
    src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/logo-mail.png?v=1651011958996"
    width="300px"
/></a>
</div>

<div
style="
  background-color: #23c495;
  display: flex;
  align-items: center;
  justify-content: center;
"
>
<div style="display: block; text-align: left; color: white; padding: 20px">
  <h1 style="font-size: 1.5em">Thank you!</h1>
  <h2
    style="
      border-radius: 5px;
      border-bottom: 15px solid #fcc515;
      font-size: 2em;
      max-width: 80%;
    "
  >
  For sending a message from the Art Open website
  </h2>
  <sub style="margin-bottom: 20px"
    >Our team <strong>will contact you shortly</strong><br />
    On business days <strong>we usually complete quotes within 24 hours</strong>.</sub
  >
</div>
<div>
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://artopen.co/contact/"
    ><img src="https://artopen.co/img/newsletter.svg" width="100%"
  /></a>
</div>
</div>
<br />
<h3 style="font-size: 2em; text-align: center; margin-top: 30px">
Get to know us better:
<br />
<sub style="font-size: 0.5em"
  >Get to know
  <a
    target="_blank"
    style="color: #23c495"
    rel="noopener noreferrer"
    href="https://artopen.co/about-us/"
    >who are we</a
  >.</sub
>
</h3>

<div style="display: flex; justify-content: center">
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/offer/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l4.png?v=1651011237576"
      width="100%"
    /><br />
    <p style="text-align: center">OFFER</p>
  </div></a
>
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/blog/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l3.png?v=1651011237576"
      width="100%"
    /><br />
    <p style="text-align: center">BLOG</p>
  </div></a
>
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/portfolio/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l2.png?v=1651011237791"
      width="100%"
    /><br />
    <p style="text-align: center">PORTFOLIO</p>
  </div></a
>
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/catalogs/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l1.png?v=1651011237864"
      width="100%"
    /><br />
    <p style="text-align: center">CATALOGS</p>
  </div></a
>
</div>
<br /><br />
<h3 style="text-align: center; font-size: 2em">
Join the action:<br />
<sub style="font-size: 0.5em"
  >See
  <a
    target="_blank"
    style="color: #23c495"
    rel="noopener noreferrer"
    href="https://artopen.co/blog/cleaning-action-open-for-environment-v-2/"
    >action footage in 2021</a
  >.</sub
>
</h3>
<br /><br />
<div style="display: block; text-align: center">
<div>
  <img
    src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/akcja-sprzatanie-3.png?v=1651239639520"
    width="100%"
    style="min-width: 200px; max-width: 500px"
  />
</div>
<div
  style="
    display: block;
    text-align: center;
    padding-left: 0px;
    color: #23c495;
  "
>
  <h1 style="font-size: 1.5em">
    Be ECO with us and join the Cleanup Action vol.III
  </h1>
  <p style="max-width:90%;margin-left:5%;">
    Being aware of the seriousness of the problem of littering, while being an agency with a strong pro-ecological profile at the same time, we decided to take matters into our own hands and for the third time we are organizing a local cleaning action. 
    <br /><br /><br />
    <a
      style="
        color: #23c495;
        border-radius: 15px;
        padding: 10px 20px;
        border: 1px solid #23c495;
      "
      target="_blank"
      rel="noopener noreferrer"
      href="https://artopen.co/akcja-sprzatanie-vol-3/"
      >join us</a
    >
  </p>
</div>
</div>
<br /><br />
<h3 style="text-align: center; font-size: 2em">
Check out the blog:<br />
<sub style="font-size: 0.5em"
  >Read
  <a
    target="_blank"
    style="color: #23c495"
    rel="noopener noreferrer"
    href="https://artopen.co/blog/"
    >our articles</a
  >.</sub
>
</h3>
<div
style="
  background-color: #23c495;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
"
>
<div style="display: block; text-align: left; padding: 20px; color: white">
  <h1 style="font-size: 2em">How to create a website?</h1>
  <p>
  What does web development look like and what does it consist of? Nowadays, a company website is a must have no matter the company’s profile. However, considering the competition online, simply having a website may not be enough.
    <br /><br /><br />
    <a
      style="
        color: white;
        border-radius: 15px;
        padding: 10px 20px;
        border: 1px solid white;
      "
      target="_blank"
      rel="noopener noreferrer"
      href="https://artopen.co/blog/jak-stworzyc-strone-internetowa/"
      >read more</a
    >
  </p>
</div>
<div>
  <img
    src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/jak.png?v=1651241484223"
    width="100%"
  />
</div>
</div>
<br /><br /><br />
<div style="display: flex; justify-content: center">
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://facebook.com/artopenstudioreklamy/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/fb.png?v=1651013201802"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://twitter.com/AReklamy/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/t.png?v=1651013201802"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://www.youtube.com/channel/UCR45VIn9iEmnQo7JU_FL2uQ/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/yt.png?v=1651013201802"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://www.linkedin.com/company/art-open-studio-reklamy/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l.png?v=1651013202010"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://www.behance.net/ArtOpenStudioReklamy/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/b.png?v=1651013202080"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://www.instagram.com/artopen.pl/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/i.png?v=1651013202263"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
</div>

<p style="text-align: center; font-size: 10px; margin-top: 10px">
We hope you enjoy our content. If you don't
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/contact/"
  >here you can withdraw your subscriptions.</a
>
</p>
<br /><br />

`
        };
        console.log(client)

        client.messages.create(DOMAIN, messageData)
         .then((res) => {
           console.log(res);
         })
         .catch((err) => {
           console.error(err);
         });


        navigate('/contact/sukces/')
      }).catch(error => alert(error))
  }

}


  render() {
    return (
      <>
      <form
        name="Covid-19"
        id = "covid"
        action="/contact/sukces/"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >

      <input name='Covid-19' id='covid-19' className='subtitle' placeholder='Covid-19:' disabled style={{color:'gray',fontFamily:'Poppins, sans-serif', backgroundColor:'white',border:'0px solid white',marginBottom:'20px'}} />
      <br />
      <div className='field' style={{marginLeft:'18px'}}>
      <div className='columns'>

        <div className='column cv1'>
          <label className='label main' htmlFor="maseczkawu" style={{fontSize:'14px'}}>
          <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='Maseczka z logo' id='maseczkawu'
          onInput={(event) => {

            if(document.getElementById('i13').style.display == 'none'){
            document.getElementById('i13').style.display = 'block';

            } else {
            document.getElementById('i13').style.display = 'none';

            }
          }} />
          <img loading="lazy" className='obrazek' src="/img/covid-form/maseczka.png" alt="reusable logo mask" width='100px' />
          <br />
          Reusable mask<br />
          with logo
          </label>
          <div id='i13' style={{display:'none'}}>
            <label className="label">Quantity:</label>
            <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='ilość maseczek z logo' id='iml' style={{maxWidth:'350px',marginBottom:'20px'}} />
            <label className='label'>Type of material:</label>
            <select selected="selected"
            className='button-green select-green' as='select'  name='Rodzaj materiału:' id='materiał'
            onChange={this.handleChange} style={{marginBottom:'20px'}}
            >
            <option value="Bawełna">Cotton</option>
            <option value="Elastyna">Elastin</option>

            </select>


            <div className='control'>
            <label className='label is-size-10'>Attach your logo:</label>
            {/*załącz logo*/}


              <div className="file">
                <label className="file-label" style={{width:'100%',cursor:'pointer'}}>
                {/*onInput ={(o) => handleInput(o)}*/}
                  <input
                    className="custom-file-input"
                    type="file"
                    name="plik"
                    style={{cursor:'pointer'}}
                    onChange={this.handleAttachment}
                    id ="fileinput"
                  />
                </label>

              </div>
              <br />
              <p style={{textAlign:'center',backgroundColor:'#111111',color:'white',padding:'2px',fontSize:'12px'}}> The maximum file size is <b>1MB</b>. </p>

            </div>
          </div>



          </div>



          <div className='column'>
            <label className='label main' htmlFor="maseczka1" style={{fontSize:'14px'}}>
            <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='Maseczka jednorazowa' id='maseczka1'
            onInput={(event) => {

              if(document.getElementById('ib').style.display == 'none'){
              document.getElementById('ib').style.display = 'block';

              } else {
              document.getElementById('ib').style.display = 'none';

              }
            }} />
            <img loading="lazy" className='obrazek' src="/img/covid-form/maseczka-jednorazowa.png" alt="surgical mask" width='100px' />
            <br />
            Disposable <br/> mask
            </label>
              <div id='ib' style={{display:'none',maxWidth:'350px',marginBottom:'20px'}}>
              <label className="label">Quantity:</label>
              <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' id='il1' name='ilość maseczek' />
              </div>






            </div>


            <div className='column'>
            <label className='label main' htmlFor="rękawiczki" style={{fontSize:'14px'}}>
            <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='Rękawiczki' id='rękawiczki'
            onInput={(event) => {

              if(document.getElementById('i19').style.display == 'none'){
              document.getElementById('i19').style.display = 'block';

              } else {
              document.getElementById('i19').style.display = 'none';

              }
            }} />
            <img loading="lazy" className='obrazek' src="/img/covid-form/rekawiczki.png" alt="gloves" width='100px' />
            <br />
            Gloves <br /><br />
            </label>
            <div id='i19' style={{display:'none',maxWidth:'350px',marginBottom:'20px'}}>
              <label className="label">Quantity:</label>
              <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='ilość rękawiczek' id='il2'  />
            </div>
            </div>



            <div className='column'>
              <label className='label main' htmlFor="płynydez" style={{fontSize:'14px'}}>
              <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='Disinfecting liquids' id='płynydez'
              onInput={(event) => {

                if(document.getElementById('i15').style.display == 'none'){
                document.getElementById('i15').style.display = 'block';

                } else {
                document.getElementById('i15').style.display = 'none';

                }
              }} />
              <img loading="lazy" className='obrazek' src="/img/covid-form/bacler.png" alt="Disinfecting liquids" width='100px' />
              <br />
              Disinfectant <br /> fluids
              </label>
                <div id="i15" style={{display:'none'}}>

                <label className='label main' htmlFor="płynydez50" style={{fontSize:'14px'}}>50 ml
                <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='50 ml' id='płynydez50'
                onInput={(event) => {

                  if(document.getElementById('i16').style.display == 'none'){
                  document.getElementById('i16').style.display = 'block';

                  } else {
                  document.getElementById('i16').style.display = 'none';

                  }
                }} />
                <span className="check"></span>
                </label>
                <div id='i16' style={{display:'none',maxWidth:'350px',marginBottom:'20px'}}>
                <label className="label">Quantity:</label>
                <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='Ilość 50ml' id='il3' />
                </div>

                <label className='label main' htmlFor="płynydez500" style={{fontSize:'14px'}}>500 ml
                <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='500 ml' id='płynydez500'
                onInput={(event) => {

                  if(document.getElementById('i17').style.display == 'none'){
                  document.getElementById('i17').style.display = 'block';

                  } else {
                  document.getElementById('i17').style.display = 'none';

                  }
                }} />
                <span className="check"></span>
                </label>
                <div id='i17' style={{display:'none',maxWidth:'350px',marginBottom:'20px'}}>
                <label className="label">Quantity:</label>
                <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='Ilość 500ml' id='il4' />
                </div>
                <label className='label main' htmlFor="płynydez1000" style={{fontSize:'14px'}}>1000 ml
                <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='1000 ml' id='płynydez1000'
                onInput={(event) => {

                  if(document.getElementById('i18').style.display == 'none'){
                  document.getElementById('i18').style.display = 'block';

                  } else {
                  document.getElementById('i18').style.display = 'none';

                  }
                }} />
                <span className="check"></span>
                </label>
                <div id='i18' style={{display:'none',maxWidth:'350px'}}>
                <label className="label">Quantity:</label>
                <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='Ilość 1000 ml' id='il5' />
                </div>


                </div>
              </div>


        </div>


      </div>

      <br />

      <div className='field' style={{marginLeft:'18px'}}>
        <div className='columns'>

          <div className='column'>
            <label className='label main' htmlFor="notouchit" style={{fontSize:'14px'}}>
            <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='Notouchit' id='notouchit'
            onInput={(event) => {

              if(document.getElementById('ib1').style.display == 'none'){
              document.getElementById('ib1').style.display = 'block';

              } else {
              document.getElementById('ib1').style.display = 'none';

              }
            }} />
            <img loading="lazy" className='obrazek' src="/img/covid-form/notouchit.png" alt="notouchit handle" width='100px' />
            <br />
            NoTouchIt<br/> Handle
            </label>
            <div id='ib1' style={{display:'none',maxWidth:'350px',marginBottom:'20px'}}>
              <label className="label">Quantity:</label>
              <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='ilość notouchit' id='il6' />
            </div>
          </div>

          <div className='column'>
            <label className='label main' htmlFor="Neilo clean" style={{fontSize:'14px'}}>
            <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='Neilo clean' id='Neilo clean'
            onInput={(event) => {

              if(document.getElementById('ib2').style.display == 'none'){
              document.getElementById('ib2').style.display = 'block';

              } else {
              document.getElementById('ib2').style.display = 'none';

              }
            }} />
            <img loading="lazy" className='obrazek' src="/img/covid-form/dlugopis-neilo-clean.png" alt="antibacterial pen" width='100px' />
            <br />
            Neilo Clean<br/> pen
            </label>
            <div id='ib2' style={{display:'none',maxWidth:'350px',marginBottom:'20px'}}>
              <label className="label">Quantity:</label>
              <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='ilość neilo clean' id='il7' />
            </div>
          </div>

          <div className='column'>
            <label className='label main' htmlFor="Rio clean" style={{fontSize:'14px'}}>
            <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='Rio clean' id='Rio clean'
            onInput={(event) => {

              if(document.getElementById('ib3').style.display == 'none'){
              document.getElementById('ib3').style.display = 'block';

              } else {
              document.getElementById('ib3').style.display = 'none';

              }
            }} />
            <img loading="lazy" className='obrazek' src="/img/covid-form/dlugopis-rio-clean.png" alt="antibacterial pen" width='100px' />
            <br />
            Rio Clean<br/> pen
            </label>
            <div id='ib3' style={{display:'none',maxWidth:'350px',marginBottom:'20px'}}>
              <label className="label">Quantity:</label>
              <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='ilość rio clean' id='il8' />
            </div>
          </div>

          <div className='column'>
            <label className='label main' htmlFor="Rio spray" style={{fontSize:'14px'}}>
            <input className='checkbox' onChange={this.handleChange} type='checkbox'  name='Rio spray' id='Rio spray'
            onInput={(event) => {

              if(document.getElementById('ib4').style.display == 'none'){
              document.getElementById('ib4').style.display = 'block';

              } else {
              document.getElementById('ib4').style.display = 'none';

              }
            }} />
            <img loading="lazy" className='obrazek' src="/img/covid-form/dlugopis-rio-spray.png" alt="antibacterial spray" width='100px' />
            <br />
            Rio Spray<br/> pen
            </label>
              <div id='ib4' style={{display:'none',maxWidth:'350px',marginBottom:'20px'}}>
              <label className="label">Quantity:</label>
              <input  onChange={this.handleChange} className='input' type='number' min="1" placeholder='100' name='ilość rio spray' id='il9' />
              </div>
          </div>

        </div>
      </div>

      <hr />



          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="Covid-19" />
          <div hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field"
              onChange={this.handleChange}
              />
            </label>
          </div>

          <div className="columns">
          <div className='column' style={{marginLeft:'15px'}}>
          <div className="field">
            <label className="label" htmlFor={'name'}>
              Name and Surname<sup>*</sup>:
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'imię i nazwisko'}
                onChange={this.handleChange}
                id={'imię i nazwisko'}
                required={true}
              />
            </div>
            </div>

            <div className="field">
              <label className="label" htmlFor={'email'}>
                E-mail address<sup>*</sup>:
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'email'}
                  name={'adres email'}
                  onChange={this.handleChange}
                  id={'adres emailII'}
                  required={true}
                />
              </div>
            </div>

            <div className="field ">
              <label className="label" htmlFor={'phone'}>
                Phone number<sup>*</sup>:
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'tel'}
                  name={'numer telefonu'} required={true}
                  onChange={this.handleChange}
                  id={'nrtel'}
                />
              </div>
            </div>


          </div>

          <div className="field column" style={{marginLeft:'15px'}}>
            <label className="label" htmlFor={'message'}>
              Message<sup>*</sup>:
            </label>
            <div className="control">
              <textarea
                className="textarea"
                type={'text'}
                name={'wiadomość'}
                onChange={this.handleChange}
                id={'wiadomość'}
                required={true}
                rows = "7"
              ></textarea>
            </div>



            <br />
            <div className="control">

            <label style={{fontSize: '12px'}} className='main'  htmlFor="privacy">   <input required={true} onChange={this.handleChange} type="checkbox" id="privacy" name="privacy"  value="true"/>I consent to the processing of my data in accordance with our <a className='link-green' href="/privacy-policy/">privacy policy</a><sup>*</sup>.<span className="check"></span></label>

            </div>

            <div className="field" style={{textAlign:'right'}}>


              <button className="button is-primary" type="submit" onSubmit={this.handleSubmit} onClick={showFileSize}>
                Send
              </button>



            </div>


          </div>



          </div>

      </form>




      </>
    )
  }
}

function showFileSize() {
let fileinput = document.getElementById('fileinput');

let file = fileinput.files[0];

if (file !== undefined){
console.log(file.size);
}

}

export default CoVid19;
